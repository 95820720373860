// import { MailIcon, PhoneIcon } from "@heroicons/react/outline";

// import { Helmet } from "react-helmet";
import React from "react";

// import { useForm, ValidationError } from "@formspree/react";
import ContactUs from "../components/contactus";

const ContactPage = () => {
  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              Contact Us
            </h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              If you have any questions,concerns or just want to send us some
              Kudos, please let us know.
            </p>
            <dl className="mt-8 text-base text-gray-500">
              {/* <div>
                <dt className="sr-only">Postal address</dt>
                <dd>
                  <p>742 Evergreen Terrace</p>
                  <p>Springfield, OR 12345</p>
                </dd>
              </div> */}
              {/* <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <PhoneIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">+1 (555) 123-4567</span>
                </dd>
              </div> */}
              {/* <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">support@sidehustletool.com</span>
                </dd>
              </div> */}
            </dl>
            <p className="mt-6 text-base text-gray-500">
              Change your mind?{" "}
              <a href="../" className="font-medium text-gray-700 underline">
                Back to Home Page
              </a>
              .
            </p>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <ContactUs />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
